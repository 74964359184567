import { useUser } from '../../auth'
import {
    AcademicCapIcon,
    BriefcaseIcon,
    CashIcon,
    CogIcon,
    CreditCardIcon,
    CubeTransparentIcon,
    DocumentSearchIcon,
    FlagIcon,
    HomeIcon,
    MapIcon,
    NewspaperIcon,
    SparklesIcon,
    UserGroupIcon,
    UsersIcon,
    UserAddIcon,
    ShoppingCartIcon,
    AdjustmentsIcon,
    EyeIcon,
    OfficeBuildingIcon,
    CollectionIcon,
} from '@heroicons/react/outline'

export interface NavigationItem {
    name: string
    href?: string
    isNew?: boolean
    isStarred?: boolean
    isDisabled?: boolean
    Icon?: Relocify.Icon
    children?: {
        name: string
        href?: string
        isNew?: boolean
        isStarred?: boolean
        isDisabled?: boolean
        Icon?: Relocify.Icon
    }[]
}

export const employerNavigation: NavigationItem[] = [
    { name: 'Employees', href: '/', Icon: UsersIcon },
    { name: 'Invitations', href: '/invite', Icon: UserAddIcon },
    { name: 'Wallet', href: '/wallet', Icon: CashIcon },
    { name: 'Settings', href: '/settings', Icon: CogIcon },
]

export const employerKnowledgeBaseNavigation: NavigationItem[] = [
    {
        name: 'Getting Started',
        href: 'https://relocify.notion.site/hr-getting-started',
        Icon: SparklesIcon,
    },
    {
        name: 'Service',
        href: 'https://relocify.notion.site/hr-service',
        Icon: CubeTransparentIcon,
    },
    // {
    //     name: 'Payments',
    //     href: 'https://wiki.relocify.nl/employer/payments',
    //     Icon: CreditCardIcon,
    // },
]

export const customerNavigation: NavigationItem[] = [
    { name: 'Dashboard', href: '/', Icon: NewspaperIcon },
    { name: 'Search filters', href: '/profile', Icon: AdjustmentsIcon },
    {
        name: 'Store',
        href: '/store',
        Icon: ShoppingCartIcon,
    },
    { name: 'Wallet', href: '/wallet', Icon: CashIcon },
    { name: 'Settings', href: '/settings', Icon: CogIcon },
    { name: 'Invitations', href: '/invite', Icon: UserAddIcon },
]

export const customerKnowledgeBaseNavigation: NavigationItem[] = [
    {
        name: 'Housing',
        Icon: HomeIcon,
        children: [
            {
                name: 'Onboarding',
                href: 'https://wiki.relocify.nl/',
            },
            {
                name: 'Rental Process',
                href: 'https://wiki.relocify.nl/rental-process',
                isStarred: true,
            },
            {
                name: 'Archiving Reasons',
                href: 'https://wiki.relocify.nl/archiving-reasons',
                isStarred: true,
            },
            {
                name: 'Utilities',
                href: 'https://wiki.relocify.nl/utilities',
                isStarred: true,
            },
            {
                name: 'Moving HHGs',
                href: 'https://wiki.relocify.nl/moving-services',
            },
            {
                name: 'Furnishing Apartment',
                href: 'https://wiki.relocify.nl/furnishing',
            },
            {
                name: 'Amsterdam',
                href: 'https://wiki.relocify.nl/amsterdam',
            },
        ],
    },
    {
        name: 'Essentials',
        Icon: CreditCardIcon,
        children: [
            {
                name: 'BSN',
                href: 'https://wiki.relocify.nl/essentials/bsn',
                isStarred: true,
            },
            {
                name: 'Banking',
                href: 'https://wiki.relocify.nl/essentials/banking',
                isStarred: true,
            },
            {
                name: 'Insurance',
                href: 'https://wiki.relocify.nl/essentials/insurance',
                isStarred: true,
            },
            {
                name: 'TB Test',
                href: 'https://wiki.relocify.nl/essentials/tb-test',
            },
            {
                name: 'Air Raid Sirens',
                href: 'https://wiki.relocify.nl/essentials/air-raid-sirens',
            },
        ],
    },
    {
        name: 'Transportation',
        Icon: MapIcon,
        children: [
            {
                name: 'Bicycle',
                href: 'https://wiki.relocify.nl/transportation/bicycle',
            },
            {
                name: 'Public Transportation',
                href: 'https://wiki.relocify.nl/transportation/public-transport',
            },
            {
                name: 'Driving License',
                href: 'https://wiki.relocify.nl/transportation/driving-license',
            },
            {
                name: 'Cars',
                href: 'https://wiki.relocify.nl/transportation/cars',
            },
            {
                name: 'Parking',
                href: 'https://wiki.relocify.nl/transportation/parking',
            },
        ],
    },
    {
        name: 'Settling In',
        Icon: FlagIcon,
        children: [
            {
                name: 'DigiD',
                href: 'https://wiki.relocify.nl/settling-in/digid',
            },
            {
                name: 'MyGovernment',
                href: 'https://wiki.relocify.nl/settling-in/my-government',
            },
            {
                name: 'Healthcare System',
                href: 'https://wiki.relocify.nl/settling-in/healthcare',
            },
            {
                name: 'Shopping',
                href: 'https://wiki.relocify.nl/settling-in/shopping',
            },
            {
                name: 'Well-being',
                href: 'https://wiki.relocify.nl/settling-in/well-being',
            },
            {
                name: 'News Sources',
                href: 'https://wiki.relocify.nl/settling-in/news-sources',
            },
            {
                name: 'Entertainment',
                href: 'https://wiki.relocify.nl/settling-in/entertainment',
            },
            {
                name: 'Buying Property',
                href: 'https://wiki.relocify.nl/settling-in/buying-property',
            },
        ],
    },
    {
        name: 'Education',
        Icon: AcademicCapIcon,
        children: [
            {
                name: 'Introduction',
                href: 'https://wiki.relocify.nl/education/introduction',
            },
            {
                name: 'Primary Education',
                href: 'https://wiki.relocify.nl/education/primary',
            },
            {
                name: 'Secondary Education',
                href: 'https://wiki.relocify.nl/education/secondary',
            },
            {
                name: 'International Schools',
                href: 'https://wiki.relocify.nl/education/international-schools',
            },
        ],
    },
    {
        name: 'Family & Pets',
        Icon: UserGroupIcon,
        children: [
            {
                name: 'Daycare',
                href: 'https://wiki.relocify.nl/family-and-pets/daycare',
            },
            {
                name: 'Child Sports',
                href: 'https://wiki.relocify.nl/family-and-pets/child-sports',
            },
            {
                name: 'Nannies',
                href: 'https://wiki.relocify.nl/family-and-pets/nannies',
            },
            {
                name: 'Pets',
                href: 'https://wiki.relocify.nl/family-and-pets/pets',
            },
        ],
    },
    {
        name: 'Work',
        Icon: BriefcaseIcon,
        children: [
            {
                name: 'Introduction',
                href: 'https://wiki.relocify.nl/work/intro',
            },
            {
                name: 'Finding Job',
                href: 'https://wiki.relocify.nl/work/finding-job',
            },
            // { name: 'Freelancing', href: 'https://wiki.relocify.nl/work/freelancing' },
            {
                name: 'Tax & Soc. Security',
                href: 'https://wiki.relocify.nl/work/taxes',
            },
        ],
    },
    {
        name: 'Integration',
        Icon: SparklesIcon,
        children: [
            {
                name: 'Dutch Language',
                href: 'https://wiki.relocify.nl/integration/language',
            },
            {
                name: 'Dutch Culture',
                href: 'https://wiki.relocify.nl/integration/culture',
            },
            {
                name: 'Short History',
                href: 'https://wiki.relocify.nl/integration/history',
            },
            {
                name: 'Dutch Art',
                href: 'https://wiki.relocify.nl/integration/art',
            },
        ],
    },
    // { name: 'Changelog', href: '/changelog' },
]

export const adminNavigation: NavigationItem[] = [
    { name: 'Properties', href: '/admin', Icon: NewspaperIcon },
    {
        name: 'My Dashboard',
        href: '/admin/dashboard',
        Icon: CollectionIcon,
    },
    {
        name: 'Search Profiles',
        href: '/search-profiles',
        Icon: DocumentSearchIcon,
    },
    {
        name: 'Store',
        href: '/store',
        Icon: ShoppingCartIcon,
    },
    { name: 'Invitations', href: '/invite', Icon: UserAddIcon },
    {
        name: 'Presentations',
        href: '/admin/presentations',
        Icon: EyeIcon,
    },
    {
        name: 'Agencies',
        href: '/admin/agencies',
        Icon: OfficeBuildingIcon,
    },
    { name: 'Settings', href: '/settings', Icon: CogIcon },
]

export const presenterNavigation: NavigationItem[] = [
    { name: 'Dashboard', href: '/presentations', Icon: NewspaperIcon },
    { name: 'Settings', href: '/settings', Icon: CogIcon },
]

export const adminKnowledgeBaseNavigation = [
    {
        name: 'For Customers',
    },
    ...customerKnowledgeBaseNavigation,
    {
        name: 'For HR',
    },
    ...employerKnowledgeBaseNavigation,
]

export function useNavigationItems(): {
    primary: NavigationItem[]
    secondary: NavigationItem[]
} {
    const { role, isLoading } = useUser()

    if (isLoading) {
        return { primary: [], secondary: [] }
    }

    if (role === 'employer') {
        return {
            primary: employerNavigation,
            secondary: employerKnowledgeBaseNavigation,
        }
    }

    if (role === 'admin') {
        return {
            primary: adminNavigation,
            secondary: adminKnowledgeBaseNavigation,
        }
    }

    if (role === 'presenter') {
        return {
            primary: presenterNavigation,
            secondary: customerKnowledgeBaseNavigation,
        }
    }

    return {
        primary: customerNavigation,
        secondary: customerKnowledgeBaseNavigation,
    }
}
