import { useSession } from 'next-auth/react'
import config from '../../config'

export function useUser() {
    const { data: session, status } = useSession()
    const name = session?.user?.name || ''
    const firstName = name.split(' ')[0] || ''

    return {
        id: (session?.user_id as number) || undefined,
        isAuth: status === 'authenticated',
        isLoading: status === 'loading',
        name,
        firstName,
        email: session?.user?.email || undefined,
        locale: (session?.locale as keyof typeof config.locales) || 'en-US',
        isNewUser: (session?.isNewUser as boolean) || false,
        role: session?.role as 'customer' | 'employer' | 'admin' | 'presenter',
    }
}
