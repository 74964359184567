import * as React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
    BellIcon,
    ChevronDownIcon,
    ChatIcon as ChatIcon,
} from '@heroicons/react/solid'
import {
    BellIcon as BellIconOutline,
    ChatIcon as ChatOutlineIcon,
    LogoutIcon,
} from '@heroicons/react/outline'
import { IoLogoApple, IoLogoAndroid } from 'react-icons/io'
import { classNames, useApp } from 'lib/shared-ui'
import { useNavigationItems } from './use-navigation-items'
import { useUser } from '../../auth'
import LoadingState from './loading-state'
import { logout } from './logout'
import { MdRemoveRedEye, MdStar, MdClose } from 'react-icons/md'
import config from '../../../config'
import { useQuery } from 'react-query'
import api from '../../api'
import { LoginIcon, UserAddIcon } from '@heroicons/react/outline'

interface DropdownMenuProps {
    name: string
    Icon?: Relocify.Icon
    isNew?: boolean
    isDisabled?: boolean
    items: {
        isNew?: boolean
        isStarred?: boolean
        isDisabled?: boolean
        name: string
        href?: string
    }[]
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
    name,
    items,
    isNew,
    isDisabled,
    Icon,
}) => {
    const router = useRouter()
    const [folded, setFolded] = React.useState(() => {
        return !items.find((item) => item.href === router.asPath)
    })

    return (
        <div>
            <MenuItem
                onClick={() => setFolded((isFolded) => !isFolded)}
                Icon={Icon}
                name={name}
                isNew={isNew}
                isDisabled={isDisabled}
                withArrow
                isSecondary
            />
            <div
                className={classNames(
                    folded ? 'hidden' : 'visible',
                    'flex flex-col'
                )}
            >
                {items.map((item) => (
                    <MenuLink
                        key={item.href}
                        path={item.href}
                        name={item.name}
                        isNew={item.isNew}
                        isStarred={item.isStarred}
                        isDisabled={item.isDisabled}
                        isSubItem
                        isSecondary
                    />
                ))}
            </div>
        </div>
    )
}

interface MenuItemProps {
    name: string
    path?: string
    Icon?: Relocify.Icon
    withArrow?: boolean
    isNew?: boolean
    isStarred?: boolean
    onClick?: () => void
    isSubItem?: boolean
    isSecondary?: boolean
    isDisabled?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({
    path,
    Icon,
    name,
    withArrow,
    isNew,
    isStarred,
    onClick,
    isSubItem,
    isDisabled,
    isSecondary,
}) => {
    const router = useRouter()
    const isActive = router.asPath === path

    return (
        <div
            className={classNames(
                isActive
                    ? 'bg-slate-200 text-gray-900 font-semibold'
                    : 'hover:bg-gray-50',
                withArrow ? 'justify-between' : '',
                isSubItem ? 'ml-4' : '',
                isSecondary ? 'text-sm' : 'text-base',
                isSubItem ? 'font-base' : 'font-medium',
                isDisabled
                    ? 'text-gray-400'
                    : 'text-gray-600 hover:text-gray-900',
                'group flex items-center px-2 py-2 rounded-md cursor-pointer'
            )}
            onClick={onClick}
        >
            <div className="flex flex-row items-center">
                {Icon && (
                    <Icon
                        className={classNames(
                            'h-5 w-5 mr-3',
                            isSecondary ? '' : 'mt-0.5'
                        )}
                    />
                )}
                {name}
                {isNew && (
                    <span className="flex h-2 w-2 relative mt-1 ml-1">
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500" />
                    </span>
                )}
                {isStarred && (
                    <MdStar
                        className={classNames(
                            'h-3 w-3 ml-1.5',
                            isActive ? 'text-gray-600' : 'text-indigo-600'
                        )}
                    />
                )}
            </div>
            {withArrow && <ChevronDownIcon className="w-5 h-5 text-gray-600" />}
        </div>
    )
}

const MenuLink: React.FC<Omit<MenuItemProps, 'withArrow'>> = ({
    path,
    Icon,
    name,
    isNew,
    isStarred,
    isSubItem,
    isDisabled,
    isSecondary,
}) => {
    return (
        <Link href={path as string} passHref>
            <a
                target={path?.startsWith('https://') ? '_blank' : ''}
                rel="noopener noreferrer"
            >
                <MenuItem
                    name={name}
                    path={path}
                    isNew={isNew}
                    isStarred={isStarred}
                    Icon={Icon}
                    isSubItem={isSubItem}
                    isDisabled={isDisabled}
                    isSecondary={isSecondary}
                />
            </a>
        </Link>
    )
}

export const Navigation: React.FC = () => {
    const app = useApp()
    const { isLoading, role, isAuth } = useUser()
    const navigation = useNavigationItems()

    if (isLoading) {
        return <LoadingState />
    }

    if (!isAuth) {
        return (
            <div className="flex flex-col px-4">
                <MenuLink path="/auth/login" Icon={LoginIcon} name="Login" />
                <MenuLink
                    path="/auth/signup"
                    Icon={UserAddIcon}
                    name="Create account"
                />
            </div>
        )
    }

    return (
        <>
            <nav className="flex flex-1 pb-4 pt-3 flex-col h-full">
                <div className="flex flex-col px-4">
                    {navigation.primary.map((item) => (
                        <MenuLink
                            key={item.href}
                            path={item.href}
                            Icon={item.Icon}
                            name={item.name}
                            isNew={item.isNew}
                            isDisabled={item.isDisabled}
                        />
                    ))}

                    {role === 'customer' && !isLoading ? (
                        <span className="hidden md:block">
                            <NotificationsMenuItem />
                        </span>
                    ) : null}
                    <MenuItem
                        onClick={logout}
                        Icon={LogoutIcon}
                        name="Sign out"
                    />
                </div>

                <ToggleDemoMode />

                <div className="w-full border-t border-gray-200 my-6" />

                <div className="px-4">
                    <h3 className="ml-1 mb-2 text-xs font-semibold text-gray-800 uppercase tracking-wider">
                        Knowledge Base
                    </h3>
                    <div className="flex flex-col">
                        {navigation.secondary.map(
                            ({
                                children,
                                isNew,
                                isStarred,
                                isDisabled,
                                name,
                                href,
                                Icon,
                            }) =>
                                children ? (
                                    <DropdownMenu
                                        items={children}
                                        name={name}
                                        Icon={Icon}
                                        isNew={isNew}
                                        isDisabled={isDisabled}
                                        key={name}
                                    />
                                ) : !href && !children ? (
                                    <MenuItem
                                        name={name}
                                        isDisabled={true}
                                        isSecondary
                                    />
                                ) : (
                                    <MenuLink
                                        key={href}
                                        path={href}
                                        Icon={Icon}
                                        name={name}
                                        isNew={isNew}
                                        isStarred={isStarred}
                                        isDisabled={isDisabled}
                                        isSecondary
                                    />
                                )
                        )}
                    </div>
                </div>

                {app.platform === 'web' && !app.adminDemoMode ? (
                    <>
                        <div className="w-full border-t border-gray-200 my-6" />

                        <div className="px-4">
                            <h3 className="ml-1 mb-2 text-xs font-semibold text-gray-800 uppercase tracking-wider">
                                Mobile Apps
                            </h3>
                            <MenuLink
                                Icon={IoLogoApple}
                                name="Download for iOS"
                                path={config.apps.ios}
                                isSecondary
                            />
                            <MenuLink
                                Icon={IoLogoAndroid}
                                name="Download for Android"
                                path={config.apps.android}
                                isSecondary
                            />
                        </div>
                    </>
                ) : null}
            </nav>
        </>
    )
}

export const ToggleDemoMode: React.FC = () => {
    const { adminDemoMode, setAdminDemoMode } = useApp()
    const { role } = useUser()

    if (role !== 'admin') return null
    return (
        <>
            <div className="w-full border-t border-gray-200 my-6" />
            <div
                onClick={() => setAdminDemoMode(!adminDemoMode)}
                className="text-sm px-6 cursor-pointer text-gray-600 flex flex-row items-center"
            >
                {adminDemoMode ? (
                    <MdClose className="h-4 w-4 mr-3" />
                ) : (
                    <MdRemoveRedEye className="h-4 w-4 mr-3" />
                )}
                {adminDemoMode ? 'Leave Demo mode' : 'Enter Demo mode'}
            </div>
        </>
    )
}

export const NotificationsMenuItem: React.FC = () => {
    const { data, isLoading } = useQuery(['notifications-count'], () =>
        api.get('notifications/mine-count')
    )

    const notificationsCount = isLoading ? 0 : data?.data?.notifications
    const messagesCount = isLoading ? 0 : data?.data?.messages
    const relocationId = isLoading ? 0 : data?.data?.relocationId
    const chatLink = '/chat/' + relocationId

    const NotificationsIcon = data?.data?.count ? BellIcon : BellIconOutline
    const MessagesIcon = data?.data?.count ? ChatIcon : ChatOutlineIcon

    return (
        <>
            <span className="hidden md:block">
                <MenuLink
                    path="/notifications"
                    Icon={NotificationsIcon}
                    name={`Notifications (${notificationsCount})`}
                />
                <MenuLink
                    path={chatLink}
                    Icon={MessagesIcon}
                    name={`Messages (${messagesCount})`}
                />
            </span>
            <Link href="/notifications">
                <span className="md:hidden relative">
                    <BellIconOutline className="h-6 w-6" />{' '}
                    {notificationsCount ? (
                        <span className="-top-2 absolute -right-2 text-xs bg-indigo-500 px-1 rounded-md text-white">
                            {notificationsCount}
                        </span>
                    ) : null}
                </span>
            </Link>
            <Link href={chatLink}>
                <span className="md:hidden relative ml-3">
                    <ChatOutlineIcon className="h-6 w-6" />{' '}
                    {messagesCount ? (
                        <span className="-top-2 absolute -right-2 text-xs bg-indigo-500 px-1 rounded-md text-white">
                            {messagesCount}
                        </span>
                    ) : null}
                </span>
            </Link>
        </>
    )
}
