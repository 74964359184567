import { logEvent } from '../../analytics'
import { signOut } from 'next-auth/react'

export function logout() {
    if (confirm('Are you sure you want to log out?')) {
        logoutWithoutWarning()
    }
}

export function logoutWithoutWarning() {
    logEvent('redox:logout')
    signOut()
}
